.main[data-v-de153d50] {
  width: 98%;
  margin: 0 auto;
}
.top[data-v-de153d50] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.overflow[data-v-de153d50] {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}